/*******************************
         Site Overrides
*******************************/

/*******************************
            Types
*******************************/


/*--------------
     Page
---------------*/

h3.ui.header {
  font-weight: @semiBold;
}

/*-------------------
       Colors
--------------------*/

/*--- Theme ---*/
.ui.theme.header {
  color: @themeColor !important;
}
a.ui.theme.header:hover {
  color: @themeColorHover !important;
}
.ui.theme.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @themeColor;
}

/*--- Primary ---*/
.ui.primary.header {
  color: @primaryColor !important;
}
a.ui.primary.header:hover {
  color: @primaryColorHover !important;
}
.ui.primary.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @primaryColor;
}

/* Inverted */
.ui.inverted.primary.header {
  color: @lightPrimaryColor !important;
}
a.ui.inverted.primary.header:hover {
  color: @lightPrimaryColorHover !important;
}

/*--- White ---*/
.ui.white.header {
  color: @white !important;
}
a.ui.white.header:hover {
  color: @whiteHover !important;
}
.ui.white.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @white;
}

/* Inverted */
.ui.inverted.white.header {
  color: @offWhite !important;
}
a.ui.inverted.white.header:hover {
  color: @offWhiteHover !important;
}