/*******************************
         Site Overrides
*******************************/

/*--------------------
        Size
---------------------*/

.ui.tiny.input {
  font-size: @tiny;
}