/*******************************
         Site Overrides
*******************************/

/*--------------------
       Disabled
---------------------*/

.ui.form .disabled.field .selection.dropdown,
.ui.form .disabled.field textarea,
.ui.form .disabled.field input {
  background: @offWhite;
}

/*--------------------
        Labels
---------------------*/
.ui.form .field > label.theme {
  color: @themeColor;
}